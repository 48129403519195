import { Link } from 'gatsby';
import { Box, css } from 'theme-ui';
import React, { CSSProperties, FC } from 'react';

type BreadcrumbLink = {
  title: string;
  link: string;
};
export type BreadcrumbProps = {
  links: BreadcrumbLink[];
  classes?: Partial<Record<'root' | 'link', CSSProperties>>;
};

const Breadcrumb: FC<BreadcrumbProps> = ({ links, classes }) => (
  <Box sx={classes.root}>
    {links
      .map(({ link, title }) => (
        <Box as={Link} sx={classes.link} to={link}>
          {title}
        </Box>
      ))
      .reduce((prev, curr) => [prev, ' / ', curr])}
  </Box>
);

export default Breadcrumb;
