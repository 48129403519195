import { Link } from 'gatsby';
import React, { FC } from 'react';
import { Box, Grid, Text } from 'theme-ui';
import { merge } from 'lodash';
import Breadcrumb, {
  BreadcrumbProps,
} from '../../../../content/components/Breadcrumb/Breadcrumb';
import { CSSProperties } from '@emotion/react/node_modules/@emotion/serialize';

const styles = {
  previewBox: {},
  title: {
    marginBottom: [15, 40],
  },
  link: {
    fontWeight: 600,
    marginBottom: 14,
  },
  list: {
    display: ['none', 'block'],
  },
  listElement: {
    fontSize: 18,
  },
  centered: {
    textAlign: ['center', 'left'],
  },
  gray: { color: '#9C9FA3' },
  active: { color: '#5F6165' },
  breadcrumb: {
    marginBottom: [15, 55],
    fontWeight: 500,
  },
  text: {
    marginBottom: [15, 20],
  },
};

export type ClassKeys = keyof typeof styles;

type ListLink = {
  name: string;
  link: string;
  active?: boolean;
};

type List = {
  title: string;
  links: ListLink[];
};

export type SupportListViewProps = {
  links?: BreadcrumbProps['links'];
  list?: List;
  styles?: Partial<Record<ClassKeys, CSSProperties>>;
};

const SupportListView: FC<SupportListViewProps> = ({
  children,
  links,
  list,
  styles: newStyles,
}) => {
  merge(styles, newStyles);

  return (
    <Box>
      <Grid gap={4} columns={[1, '1fr 3fr']}>
        <Text />
        <Text sx={styles.centered}>
          <Breadcrumb
            classes={{
              root: styles.breadcrumb,
              link: styles.gray,
            }}
            links={links}
          />
        </Text>
      </Grid>
      <Grid gap={4} columns={[1, '1fr 3fr']}>
        <Box sx={{ ...styles.list, ...styles.centered }}>
          <Text sx={styles.listElement} variant='h2'>
            {list.title}
          </Text>
          {list.links.map(({ name, link, active }) => (
            <Text
              sx={{
                ...styles.link,
                ...styles.listElement,
                ...(active ? styles.active : styles.gray),
              }}
              key={`${link}-Text`}
              variant='h2'
            >
              <Link to={link}>{name}</Link>
            </Text>
          ))}
        </Box>
        <Box sx={styles.previewBox}>{children}</Box>
      </Grid>
    </Box>
  );
};

export default SupportListView;
