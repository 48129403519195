import React, { FC, ReactNode, useState } from 'react';
import { Heading, Container, Box, Text } from 'theme-ui';
import SearchInput from '../../../../content/components/SearchInput';
import { Link } from 'gatsby';
import { SearchInputProps } from '../../../../content/components/SearchInput/SearchInput';

const styles = {
  searchInput: {
    root: {
      maxWidth: 450,
      margin: '28px auto 53px auto',
    },
    inner: { flexDirection: 'row-reverse' },
    button: {
      marginRight: 10,
    },
  },
  wrapper: {
    backgroundColor: '#F9F9FC',
    padding: ['10px 0 50px 0', '50px 0'],
  },
  footer: {
    textAlign: 'center',
    marginTop: '3rem',
    fontSize: 12,
  },
};

export type SupportWrapperProps = {
  placeholder: string;
  searchData?: SearchInputProps['data'];
  title: string;
  footer: ReactNode;
};
const SupportWrapper: FC<SupportWrapperProps> = ({
  placeholder,
  searchData,
  children,
  title,
  footer,
}) => {
  const [query, setQuery] = useState('');

  return (
    <Box>
      <Container style={{ maxWidth: '1080px' }} mt={6}>
        <Heading variant='h3' sx={{ textAlign: 'center' }}>
          {title}
        </Heading>
        <SearchInput
          classes={{
            root: styles.searchInput.root,
            inner: styles.searchInput.inner,
            button: styles.searchInput.button,
          }}
          onChange={({ target: { value } }) => setQuery(value)}
          onReset={() => setQuery('')}
          placeholder={placeholder}
          value={query}
          data={searchData}
        />
      </Container>
      <Box sx={styles.wrapper}>
        <Container>
          <Box>{children}</Box>
        </Container>
        <Box sx={styles.footer}>{footer}</Box>
      </Box>
    </Box>
  );
};

export default SupportWrapper;
