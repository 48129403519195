import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@theme-ui/mdx';
import components from '@components/Mdx';
import { GatsbyImage } from 'gatsby-plugin-image';


const isButton = (alt) =>
  [
    'registrati button',
    'sign up button',
    'Sign up now',
    'Regístrate ',
  ].includes(alt);

export const ImageComponent = (props) => {
  const image = props?.src?.generatedImage;
  
  if(!image) return <div />;

  return <GatsbyImage image={image} alt={props.alt} />;
}
export const CustomParagraph = (props) => !isButton(props?.children?.props?.children?.props?.alt) && <p {...props} />
export const PostBody = ({ body, components: inheritedProps = {} }) => <div><MDXProvider
        components={{
          ...components,
          ...inheritedProps,
          p: CustomParagraph,
          img: ImageComponent,
        }}
      >
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
      </div>

