import React from 'react';
import { merge, Box, Text } from 'theme-ui';
import Seo from '@solid-ui-components/Seo';
import Layout from '@solid-ui-layout/Layout';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import { PostBody } from '@widgets/Post';
import theme from '../../pages/new-landing/_theme';
import SupportWrapper from './components/SupportWrapper';
import SupportListView from './components/SupportListView';
import { graphql } from 'gatsby';
import ConvertTextToComponent from '../../../content/components/ConvertTextToComponent';

const styles = {
  previewBox: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 19px -7px rgba(0, 0, 0, 0.25)',
    flex: 1,
    borderRadius: 10,
    padding: ['15px 25px', '52px 70px'],
  },
  title: {
    marginBottom: [15, 40],
  },
  body: {
    '& *': {
      fontSize: '1rem !important',
    },
    '& ol, & ul': {
      paddingLeft: '2rem',
    },
  },
};

const SupportQuestion = (props) => {
  const {
    allBlockContent,
    faqQuestion,
    relatedFaqQuestions,
    allFaqQuestions: { nodes: allFaqQuestions },
  } = props.data;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  const question = faqQuestion.nodes?.[0];

  return (
    <Layout theme={merge(theme, { ...flowUITheme, colors: {} })} {...props}>
      <Seo title={`Support - ${question.category.name} - ${question.title}`} />
      <Header content={content['header']} />
      <SupportWrapper
        title={content['support-index']?.kicker.text}
        placeholder={content['faq-categories']?.description.textGroup[0]}
        searchData={allFaqQuestions}
        footer={
          <ConvertTextToComponent
            content={content['support-index'].description.detailedTexts}
            marginX='4'
            marginY='2'
          />
        }
      >
        <SupportListView
          links={[
            { title: 'Home', link: '/support' },
            {
              title: question.category.name,
              link: `/support/${question.category.slug}`,
            },
            {
              title: question.title,
              link: `/support/${question.category.slug}/${question.slug}`,
            },
          ]}
          list={{
            title: content['support-category'].text_2.text,
            links: relatedFaqQuestions.nodes.map(
              ({ slug, title, category }) => ({
                name: title,
                link: `/support/${category.slug}/${slug}`,
              })
            ),
          }}
        >
          <Box sx={styles.previewBox}>
            <Text sx={styles.title} variant='h2'>
              {question.title}
            </Text>
            <Box sx={styles.body}>
              <PostBody body={question.body} />
            </Box>
          </Box>
        </SupportListView>
      </SupportWrapper>
      <Footer content={content['footer']} />
    </Layout>
  );
};

export const query = graphql`
  query faqQuestionsQuery(
    $id: String
    $categorySlug: String
    $blocksPaths: [String]!
  ) {
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: {
          in: [
            "header"
            "footer"
            "support-index"
            "support-category"
            "faq-categories"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
    faqQuestion: allFaqQuestion(filter: { id: { eq: $id } }, limit: 1) {
      nodes {
        ...FaqQuestion
      }
    }
    relatedFaqQuestions: allFaqQuestion(
      filter: { id: { ne: $id }, category: { slug: { eq: $categorySlug } } }
      sort: { fields: index, order: ASC }
      limit: 5
    ) {
      nodes {
        title
        slug
        category {
          slug
        }
      }
    }
    allFaqQuestions: allFaqQuestion(sort: { fields: index, order: ASC }) {
      nodes {
        title
        slug
        category {
          name
          slug
        }
      }
    }
  }
`;

export default SupportQuestion;
